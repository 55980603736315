import QueryString from './querystring';
import store from './store.js';
import codebird from './codebird';
import Vue from 'vue'

var twitter = {};
var cb = new codebird;
twitter.cb = cb;

// ADD YOUR KEYS HERE! If you have none, go to https://apps.twitter.com and generate them!
cb.setConsumerKey(Vue.prototype.twitterKey, Vue.prototype.twitterSecret);

var oauth_token = QueryString.oauth_token;
var oauth_verifier = QueryString.oauth_verifier;
// localStorage.clear();

function saveTokens (oauth_token, oauth_token_secret) {
  localStorage.setItem('oauth_token', oauth_token);
  localStorage.setItem('oauth_token_secret', oauth_token_secret);
}

twitter.login = async function (endpoint) {
  const response = (await Vue.prototype.$http.post(
    '/api/Twitter/GetRequestToken?endpointCallback='+endpoint,
    {},
    { validateStatus: () => true },
  )).data.result
  
  cb.setToken(response.token, response.secret);
  saveTokens(response.token, response.secret);

  cb.__call(
      "oauth_authorize",
      {},
      function (auth_url) {
        location.href = response.redirectUrl;
      }
  )
}

twitter.getAuthorizationOAuth = async function (accessToken, accessTokenSecret) {
  cb.setToken(accessToken, accessTokenSecret);
  return cb._sign('GET', 'https://api.twitter.com/1.1/account/verify_credentials.json', {})
}

export default twitter;
