const menu = {
  admin: [
    {
      title: 'Dashboard',
      route: 'dashboard',
      icon: 'HomeIcon',
    },
    {
      title: 'FAQ',
      route: 'faq-admin',
      icon: 'HelpCircleIcon',
    },
    {
      title: 'Blog',
      route: 'blog-admin',
      icon: 'MessageSquareIcon',
    },
    {
      title: 'Investimentos',
      icon: 'DollarSignIcon',
      children: [
        {
          title: 'Gestão de Cotas e Comissões',
          route: 'manager-quotas',
          icon: 'TrendingUpIcon',
        },
        {
          title: 'Cancelamentos',
          route: 'list-cancell-investment',
          icon: 'SlashIcon',
        },
      ]
    },
    {
      title: 'Usuários',
      route: 'user-list',
      icon: 'UsersIcon',
    },
    {
      title: 'Investidores',
      route: 'investors-list',
      icon: 'UsersIcon',
    },
    {
      title: 'Construtora',
      route: 'company-list',
      icon: 'UsersIcon',
      children: [
        {
          title: 'Deliberação',
          route: 'deliberation-company-list',
        },
        {
          title: 'Construtoras',
          route: 'company-list',
        },
        {
          title: 'Projeto Obra',
          route: 'project',
        },
      ],
    },
    {
      title: 'Imóvel',
      route: 'company-list',
      icon: 'UsersIcon',
      children: [
        {
          title: 'Imóveis',
          route: 'adm-company-immobile-list',
        },
        {
          title: 'Deliberação',
          route: 'adm-company-immobile-deliberation-list',
        },
        {
          title: 'Publicação',
          route: 'adm-company-immobile-public-list',
        },
      ],
    },
    {
      title: 'Corretores',
      icon: 'UsersIcon',
      children: [
        {
          title: 'Deliberação',
          route: 'deliberation',
        },
        {
          title: 'Corretores',
          route: 'broker-list',
        },
      ],
    },
    {
      title: 'Notificação',
      icon: 'BellIcon',
      children: [
        {
          title: 'Lista de Notificações',
          route: 'notification-list',
        },
        {
          title: 'Cadastrar',
          route: 'notification-register',
        },
      ],
    },
    {
      title: 'Questionário',
      route: 'quiz-control',
      icon: 'MenuIcon',
    },
    {
      title: 'Configurações',
      icon: 'BellIcon',
      children: [
        {
          title: 'Configurar Número Especialista',
          route: 'talk-specialist',
        },
      ],
    },
  ],
  broker: [
    {
      title: 'Dashboard',
      route: 'dashboard',
      icon: 'HomeIcon',
    },
    {
      title: 'Extrato',
      route: 'broker-commissions',
      icon: 'BarChartIcon',
    },
    {
      title: 'Imóveis',
      route: 'broker-immobiles',
      icon: 'HomeIcon',
    },
    {
      title: 'Notificação',
      icon: 'BellIcon',
      children: [
        {
          title: 'Notificações',
          route: 'notification-list-user',
        },
      ],
    },
  ],
  company: [
    {
      title: 'Dashboard',
      route: 'dashboard',
      icon: 'HomeIcon',
    },
    {
      title: 'Notificação',
      icon: 'BellIcon',
      children: [
        {
          title: 'Notificações',
          route: 'notification-list-user',
        },
      ],
    },
    {
      title: 'Imóvel',
      icon: 'TrelloIcon',
      children: [
        {
          title: 'Imóveis',
          route: 'company-immobile-list',
        },
      ],
    },
    {
      title: 'Projeto Obra',
      icon: 'TrelloIcon',
      route: 'project',
    },
  ],
  user: [
    {
      title: 'Dashboard',
      route: 'dashboard',
      icon: 'HomeIcon',
    },
    {
      title: 'Notificação',
      icon: 'BellIcon',
      children: [
        {
          title: 'Notificações',
          route: 'notification-list-user',
        },
      ],
    },
    {
      title: 'Meus Investimentos',
      icon: 'LayersIcon',
      children: [
        {
          title: 'Pendente',
          route: 'investments-user-pending',
        },
        {
          title: 'Em Andamento',
          route: 'investments-user-progress',
        },
        {
          title: 'Encerrados',
          route: 'investments-user-closed',
        },
        {
          title: 'Vendidos',
          route: 'investments-user-sold',
        },
      ],
    },
    {
      title: 'Questionário',
      route: 'quiz-first',
      icon: 'MenuIcon',
    },
  ],
  brokerAnalysis: [],
  marketing: [
    {
      title: 'Dashboard',
      route: 'dashboard',
      icon: 'HomeIcon',
    },
    {
      title: 'Blog',
      route: 'blog-admin',
      icon: 'MessageSquareIcon',
    },
  ],
}

export default menu
